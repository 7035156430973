import React from "react";
import "./Herocontentl.css";
import hero from "../../assets/images/heroimg.png";
import navdot from "../../assets/images/hero-nav.svg";
import { FaSearch } from "react-icons/fa";
import { FaBriefcase } from "react-icons/fa6";


function HerocontentS() {
  return (
    <div className="hero-container">
      <div className="hero-sec-content1">
        <div className="hero-text-container1">
          <h2>
          Discover your next
          </h2>
          <h2>career move with us!</h2>
        </div>
         <div className="sarch_section">
            
            <div className="header_search_container">
              <FaSearch
                className="search_icon"
                
              />
              <input
                 className="header_search"
                 type="search"
                 placeholder="Enter skills (comma separated)"
                //  value={skills.join(', ')}
                //  onChange={handleSkillsChange}
              />
            </div>

            <div className="header_select_container">
              <FaBriefcase className="experience_icon" />
              <input
                 placeholder="Experience"
                //  value={experience}
                //  onChange={(e) => setExperience(e.target.value)}
              />
            </div>

            <div className="header_input_container">
              <div className="heder_input">
                <input
                  
              type="text"
              placeholder="Enter location"
            //   value={location}
            //   onChange={(e) => setLocation(e.target.value)}
                
                />
              </div>
            </div>
            <div>
            <button className="header_search_button">
           <a href="https://risohire.com">Search</a>
          </button>
            </div>
          </div>
      </div>
    </div>
  );
}

export default HerocontentS;
